import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import Image from "gatsby-image"
import TopLogo from "../images/logoBrown.svg"

const StyledBack = styled.div`
    position:fixed;
    z-index:-1;
    width:100%;
    height:100vh;
    top:0;
    left:0;
    .gatsby-image-wrapper{
      width:100%;
      height:100%;
      object-fit:cover;
    }
`

const StyledDiv = styled.div`
  display:flex;
  flex-flow:column nowrap;
  align-items:center;
  justify-content:center;
  height:100vh;
  position:relative;
  #logoArea{
    width:250px;
    height:250px;
    background:rgba(255,255,255,.8);
    display:flex;
    flex-flow:row nowrap;
    align-items:center;
    justify-content:center;
    #logoAreaWrap{
      border:1px solid ${({ theme }) => theme.colors.primary.brown};
      width:230px;
      height:230px;
      display:flex;
      flex-flow:row nowrap;
      align-items:center;
      justify-content:center;
      img{
        width:90%;
        height:auto;
      }
    }
  }
  .titleTxt{
    width:80%;
    padding:30px;
    color:white;
    display:flex;
    flex-flow:column nowrap;
    align-items:center;
    .ttjp{
      text-align:center;
      font-size:1.1em;
      margin-top:1.32em;
    }
  }

`

const TopMainVisual = () => {
  const data = useStaticQuery(query)
  return (
    <>
      <StyledBack>
        <Image fluid={data.zoo.heroMedia.childImageSharp.fluid} />
      </StyledBack>
      <StyledDiv>
        <div id="logoArea">
          <div id="logoAreaWrap">
            <img src={TopLogo} alt='アリンタロイヤルリゾート' />
          </div>
        </div>
        <div className="titleTxt">
          <p className="tten">{data.zoo.subTitle1}</p>
          <p className="ttjp" dangerouslySetInnerHTML={{ __html: data.zoo.subTitle2 }} />
        </div>
      </StyledDiv>
    </>
  )
}

export const query = graphql`
  {
    zoo:strapiIndexMemo {
      subTitle1
      subTitle2
      heroMedia {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`

export default TopMainVisual
