import React from "react"
import styled, { css } from "styled-components"
import { media } from "../styles/media"
import { FiSmartphone } from "react-icons/fi"
import { IconContext } from "react-icons"
import HotPepper from "../images/hpb_bunner.png"

const IconLine = () => (
  <IconContext.Provider value={{ color: "white", size: "25px" }}>
    <FiSmartphone />
  </IconContext.Provider>
)

const StyledWrap = styled.div`
${() => media.tablet(css`
  padding:30px 30%;
`
)}
  background:black;
  padding:30px 0;
  display:flex;
  flex-flow:column nowrap;
  align-items:center;
  color:white;
  h3{
    border:1px solid white;
    padding:.32em 1.62em;
    font-size:1.32em;
    margin-bottom:1.62em;
  }
  p{
    width:90%;
    margin:0 auto;
  }
  .hours{
    text-align:center;
    font-size:1em;
    color:${({ theme }) => theme.colors.primary.beige};
    padding:.62em 0 1.62em;
  }
  .iconLists{
    width:90%;
    margin:0 auto;
    display:flex;
    flex-flow:column nowrap;
    align-items:center;
    overflow:hidden;
    a{
      width:100%;
      border:3px solid white;
      margin-bottom:30px;
      height:60px;
      display:flex;
      flex-flow:row nowrap;
      align-items:center;
      justify-content:center;
      overflow:hidden;
      svg{
        margin-right:.32em;
        animation:animes 2s linear 0s forwards infinite;
      }
      &:nth-child(2){
        background:white;
      }
      img{
        object-fit:contain;
        height:100%;
      }
    }
  }
  @keyframes animes{
    0%{transform:rotate(0deg);}
    10%{transform:rotate(20deg);}
    20%{transform:rotate(-10deg);}
    30%{transform:rotate(0deg);}
    100%{transform:rotate(0deg);}
  }
`

const TopContact = () => {
  return (
    <StyledWrap>
      <h3>contact</h3>
      <p>ご予約は「お電話」、「HOTPEPPER Beauty」にて承っております。</p>
      <p className="hours">
        営業時間 : <span>10:00 ~ 20:00</span><br />
      </p>
      <div className="iconLists">
        <a data-tel='tel' href="tel:03-6662-7281"><IconLine />03-6662-7281</a>
        <a data-hpb='hpb' href="https://beauty.hotpepper.jp/kr/slnH000500928/" target="_blank" rel="noopener noreferrer"><img src={HotPepper} alt='アリンタロイヤルリゾートからホットペッパービューティーへ' /></a>
      </div>

    </StyledWrap>
  )
}

export default TopContact
