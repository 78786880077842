import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

const StyledWrap = styled.div`
  background:black;
  padding:30px 0;
  display:flex;
  flex-flow:column nowrap;
  align-items:center;
  color:white;
  h3{
    border:1px solid white;
    padding:.32em 1.62em;
    font-size:1.32em;
    margin-bottom:1.62em;
  }
  .textAccess{
    display:flex;
    flex-flow:column nowrap;
    align-items:center;
    h2{
      font-size:1.32em;
      padding:.32em 0;
    }
    p{
      margin:1.62em 0 .62em;
    }
    span{
      margin-bottom:50px;
      a{
        color:${({ theme }) => theme.colors.primary.beige};
        font-size:1.32em;
      }
    }
  }
  .mapArea{
    width:100%;
    iframe{
      width:100%;
    }
  }
`

const TopAccess = () => {
  const data = useStaticQuery(query)
  return (
    <StyledWrap>
      <h3>access</h3>
      <div className="textAccess">
        <h2>Aleenta Royal Resort</h2>
        <h2>アリンタロイヤルリゾート</h2>
        <p>東京都葛飾区亀有3-37-31<br />アビタシオン森1F</p>
        <span>TEL : <a href="tel:03-6662-7281">03-6662-7281</a></span>
      </div>
      <div className="mapArea" dangerouslySetInnerHTML={{ __html: data.zoo.mapIframe }} />
    </StyledWrap>
  )
}


export const query = graphql`
  {
    zoo:strapiIndexMemo {
      mapIframe
    }
  }
`

export default TopAccess
