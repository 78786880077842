import React from "react"
import styled, { css } from "styled-components"
import { media } from "../styles/media"
import { Link, useStaticQuery, graphql } from "gatsby"
import BackImage from "../images/backgroundBlack.png"
import BtnImage from "../images/back2.png"


import { AiOutlineSwapRight } from "react-icons/ai"
import { IconContext } from "react-icons"

const IconArr = () => (
  <IconContext.Provider value={{ color: "black", size: "30px" }}>
    <AiOutlineSwapRight />
  </IconContext.Provider>
)

const StyledWrap = styled.div`
  position:relative;
  display:flex;
  flex-flow:column nowrap;
  align-items:center;
  background:rgba(0,0,0,0.8);
  padding:50px 5%;
  .bakground{
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    background-image:url(${BackImage});
    z-index:-1;
  }
  h3{
    color:white;
    font-size:1.32em;
    display:flex;
    flex-flow:column nowrap;
    align-items:center;
    &:after{
      display:block;
      content:"";
      width:38%;
      height:2px;
      border-radius:3px;
      background:white;
      margin:.62em 0 1.62em;
    }
  }
  .lists{
    color:white;
    .linkList{
      margin:1.62em 0;
      display:flex;
      flex-flow:row wrap;
      position:relative;
      .sDate{
        width:100%;
        color:gray;
        display:flex;
        flex-flow:row nowrap;
        align-items:center;
        &:after{
          display:block;
          content:"";
          width:10%;
          height:1px;
          background:gray;
          margin-left:.62em;
        }
      }
      .sTitle{
        width:100%;
        font-size:1.32em;
        padding-left:.62em;
      }
      a{
        position:absolute;
        width:100%;
        height:100%;
        top:0;
        left:0;
      }
    }
  }
  .moreBtn{
    ${() => media.tablet(css`
    width:30%;
    `
)}
    width:100%;
    display:flex;
    flex-flow:row nowrap;
    justify-content:center;
    padding:30px 0;
    background:white;
    margin-top:30px;
    position:relative;
    a{
      display:block;
      display:flex;
      flex-flow:row nowrap;
      align-items:center;
      position:absolute;
      width:100%;
      height:100%;
      top:0;
      left:0;
      display:flex;
      flex-flow:row nowrap;
      align-items:center;
      justify-content:center;
      background-image:url(${BtnImage});
      background-size:70%;
      overflow:hidden;
      color:black;
      span{
        position:absolute;
        width:100px;
        height:100px;
        border-radius:100px;
        border:1px solid ${({ theme }) => theme.colors.primary.beige};
        animation:anime 3s linear 0s forwards infinite;
      }
    }
  }
  @keyframes anime {
    0%{opacity:1; transform:scale(.1);}
    30%{opacity:0; transform:scale(1);}
    100%{opacity:0; transform:scale(1);}
  }
`

const TopBlogs = () => {
  const data = useStaticQuery(query);
  return (
    <StyledWrap>
      <div className="bakground"></div>
      <h3>お知らせ</h3>
      <div className="lists">
        {data.zoo.nodes.map((panda, index) => (
          <div className="linkList" key={index}>
            <span className="sDate">{panda.blogDate}</span>
            <span className="sTitle">{panda.blogTitle}</span>
            <Link to={"/release/" + panda.slug}></Link>
          </div>
        ))}
      </div>
      <div className="moreBtn">
        <Link to="/release/">more<IconArr /><span></span></Link>
      </div>
    </StyledWrap>
  )
}

export const query = graphql`
  {
    zoo:allStrapiAleentaBlog(sort: {fields: blogDate, order: DESC}, limit: 5) {
      nodes {
        blogDate
        blogTitle
        slug
      }
    }
  }
`

export default TopBlogs
