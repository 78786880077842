import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import styled, { css } from "styled-components"
import { media } from "../styles/media"
import Image from "gatsby-image"
import { AiOutlineSwapRight } from "react-icons/ai"
import { IconContext } from "react-icons"

const IconArr = () => (
  <IconContext.Provider value={{ color: "white", size: "20px" }}>
    <AiOutlineSwapRight />
  </IconContext.Provider>
)

const StyledWrap = styled.div`
${() => media.tablet(css`
  display:flex;
  flex-flow:row wrap;
  align-items:center;
  .imgLink{
    width:50%;
    border:2px solid white;
    &:first-child{
      border-right:none;
    }
  }
`
)}
  background:rgba(0,0,0,.8);
  .imgLink{
    display:flex;
    flex-flow:row nowrap;
    align-items:center;
    border-bottom:2px solid white;
    position:relative;
    &:first-child{
      border-top:2px solid white;
    }
    p{
      width:50%;
      color:white;
      display:flex;
      flex-flow:row nowrap;
      align-items:center;
      padding-left:5%;
      svg{
        margin-left:.32em;
        animation:movess 2s linear 0s forwards infinite;
      }
    }
    .gatsby-image-wrapper{
      width:50%;
    }
    a{
      position:absolute;
      width:100%;
      height:100%;
      top:0;
      left:0;
    }
  }

    @keyframes movess {
    0%{transform:translateX(0px);}
    10%{transform:translateX(-3px);}
    20%{transform:translateX(0px);}
    30%{transform:translateX(5px);}
    40%{transform:translateX(0px);}
    100%{transform:translateX(0px);}
  }
`

const TopMenu = () => {
  const data = useStaticQuery(query);
  return (
    <StyledWrap>
      {data.zoo.topMenu1.map((panda, index) => (
        <div className="imgLink" key={index}>
          <p>{panda.linkTxt}<IconArr /></p>
          <Image fluid={panda.linkImage.childImageSharp.fluid} alt='アリンタロイヤルリゾートのメニュー' />
          <Link to={panda.linkTo} key={index}></Link>
        </div>
      ))}
    </StyledWrap>
  )
}

export const query = graphql`
  {
    zoo:strapiIndexMemo {
      topMenu1 {
        linkImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        linkTo
        linkTxt
      }
    }
  }
`

export default TopMenu
