import React, { useState } from "react"
// import { Link } from "gatsby"
import { Element } from "react-scroll"
// import Image from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

// components
import SEO from "../components/SEO"
import Layout from "../components/Layout"
import TopMainVisual from "../components/TopMainVisual"
import TopMenu from "../components/TopMenu"
import TopMenuBig from "../components/TopMenuBig"
import TopBlogs from "../components/TopBlogs"
import TopAccess from "../components/TopAccess"
import TopContact from "../components/TopContact"
import TopInstagram from '../components/TopInstagram'


const IndexPage = () => {
  const data = useStaticQuery(query)

  const jsonld = [
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "item": {
          "@id": `${data.seo.pagesSeo[0].pageUrl}`,
          "name": `${data.seo.pagesSeo[0].pageTitle}`,
          "image": `${data.seo.pagesSeo[0].pageMedia.absolutePath}`
        }
      }]
    }
  ]

  return (
    <Layout>
      <SEO
        title={data.seo.pagesSeo[0].pageTitle}
        description={data.seo.pagesSeo[0].pageDescript}
        jsonld={jsonld}
        pageUrl={data.seo.pagesSeo[0].pageUrl}
        type='article'
        imageUrl={data.seo.pagesSeo[0].pageMedia.absolutePath}
      />
      <TopMainVisual />
      {/* <MainVisual setIsFBiconActive={setIsFBiconActive} /> */}
      {/* <FacebookIframe
        isIconActive={isFBiconActive}
        setIsIconActive={setIsFBiconActive}
      /> */}
      <TopMenu />
      <TopMenuBig />
      <TopBlogs />
      <TopAccess />
      <TopContact />
      <TopInstagram />

      {/* const IGid = 17841439535218608
      const Token3 =EAAFrIYrfZAawBAIdb18EiDYX79S5dYBj3rqpZBhEwNS53EM489ycxrS9mpggfvTVJHOOmNkZB4dbugbtvRi1v3RFDSMQaqC2e1lbSz3AFVnOpP20lZCF8d0wggmurOQtWNZBHDrduAo5G49zjAEEtPLMKnTyYUWhppZCJUeDQcQHQqmZBEuAvTI */}

      {/* <Element name="sample-scroll">
        <div style={{ height: "300px", backgroundColor: "#eee" }}>
          some component here!
        </div>
      </Element>
      <div style={{ height: "300px", backgroundColor: "#333", color: "#fff" }}>
        some component here!
      </div> */}
    </Layout>
  )
}

export const query = graphql`
  {
    zoo:strapiMenus {
      pageTitle
      dsihSubtitles
      pageImageALt
      pageHeaderMedia {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    seo: strapiSeo {
      pagesSeo {
        pageDescript
        id
        pageTitle
        pageUrl
        pageMedia {
          absolutePath
        }
      }
      personName
      organizeName
      logoImage {
        absolutePath
      }
    }
  }
`

export default IndexPage
