import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import styled, { css } from "styled-components"
import { media } from "../styles/media"
import { BiChevronRight } from "react-icons/bi"
import { IconContext } from "react-icons"

const IconArr = () => (
  <IconContext.Provider value={{ color: "white", size: "20px" }}>
    <BiChevronRight />
  </IconContext.Provider>
)


const StyledWrap = styled.div`
${() => media.tablet(css`
  .gatsby-image-wrapper{
    width:40%!important;
  }
`
)}
  background:rgba(0,0,0,0.95);
  display:flex;
  flex-flow:column nowrap;
  align-items:center;
  position:relative;
  p{
    color:white;
    display:flex;
    flex-flow:row nowrap;
    align-items:center;
    line-height:3em;
    margin-top:2em;
    text-align:center;
  }
  .gatsby-image-wrapper{
    width:90%;
  }
  a{
    display:block;
    content:"";
    width:100%;
    height:100%;
    position:absolute;
    top:0;
    left:0;
  }
`

const TopMenuBig = () => {
  const data = useStaticQuery(query);
  return (
    <>
      {data.zoo.bigMedia.map((panda, index) => (
        <StyledWrap key={index}>
          <p><IconArr />{panda.linkTo}</p>
          <Image fluid={panda.linkMedia.childImageSharp.fluid} alt='アリンタロイヤルリゾートのフェイスマッサージ' />
          <Link to="/aboutus/"></Link>
        </StyledWrap>
      ))}
    </>
  )
}

export const query = graphql`
  {
    zoo:strapiIndexMemo {
      bigMedia {
        linkTo
        linkUrl
        linkMedia {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

export default TopMenuBig
