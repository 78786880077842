import React from 'react'
import InstagramEmbed from 'react-instagram-embed'
import styled from 'styled-components'

const StyledWrap = styled.div`
  background:black;
    display:flex;
    flex-flow:row nowrap;
    align-items:center;
    justify-content:center;
  iframe{
    margin:30px 0 0!important;
  }
`

const TopIg = () => {
  return (
    <StyledWrap>
      <InstagramEmbed
        url='https://www.instagram.com/p/CJQF6ENnyPl/'
        clientAccessToken='EAAnoC5D3ULABAIIOlBQSvuZAZC3YKT2fSFB4aTcwfO0VT5hd2PNkmiH79PUsZAsJYNbeKP3MTgd9RhNC8FXAd11Utumo8HRZAI8HtwUZCJpTG3ytCKvGn8YDTYzIXe9Tqw5NTGzaCtAWvHuu04K1OsjNedC6owTNgZC4mjKAEbiQZDZD'
        maxWidth={320}
        hideCaption={true}
        containerTagName='div'
        protocol=''
        injectScript
        onLoading={() => { }}
        onSuccess={() => { }}
        onAfterRender={() => { }}
        onFailure={() => { }}
      />
    </StyledWrap>
  )
}

export default TopIg
